/* :root {
  --bio-color: #192a51;
  --projects-color: #197278;
  --skills-color: rgb(40,17,43);
  --contact-color: #96616b;
} */

:root {
  --bio-color: #18284E;
  --projects-color: #26407D;
  --skills-color: #3558AC;
  --contact-color: #5377CA;
}

html, body {
  width: 100%;
}

body {
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content {
  position:relative;
  left: 0;
  transition: left .7s;
}

@media (max-width: 749px) {

  body {
    position: relative;
  }

  .content-shift {
    position: relative;
    left: 40vw;
    transition: left .7s;
  }
}

.portfolio-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.detail-container {
  /* make detail container fill the viewbox */
  margin: 0;
  padding: 24px 0;
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.detail-container-pane1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.detail-container-pane2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 0 10px;
}

/* .detail-container-pane2 h1, h2 {
  margin: 5px;
} */

@media (max-width: 749px) {

  .detail-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .detail-container-pane1 {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .detail-container-pane2 {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

}

  /* Biodetail */

.biodetail-container {
  background-color: var(--bio-color);
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.biodetail-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.biodetail-container h2 {
  color: #7b98dd;
}

.biodetail-icon-img {
  border: 3px solid white;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  align-self: center;
}

@media (max-width: 749px) {

  .biodetail-icon-img {
    width: 40vw;
    height: 40vw;
  }

  .biodetail-container {
    width: 100vw;
  }

  .biodetail-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

  /* Nav */

.nav-container-fixed {
  width: 10vw;
  position: relative;
}

.nav-container {
  display: flex;
  position: fixed;
  width: 10vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  /* border-right: 1px solid  rgb(164, 170, 201); */
  color: rgb(71, 78, 119);
  box-sizing: border-box;
  padding: 0;
}

.nav-container > div {
  position: relative;
  cursor: pointer;
  color: white;
  font-weight: 600;
  width: 10vw;
  height: 25vh;
  transition: font-size .7s;
}

.nav-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-container > div:hover {
  font-size: 125%;
}

.nav-container-togglebtn {
  position: fixed;
  visibility: hidden;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 749px) {
  .nav-container-fixed {
    position: absolute;
    width: 40vw;
    left: -40vw;
    transition: left .7s;
    overflow: hidden;
  }

  .nav-container-fixed-show {
    left: 0;
    transition: left .7s;
  }

  .nav-container {

    width: 40vw;
    left: -40vw;
    transition: left .7s;
  }

  .nav-container-show {
    left: 0;
    transition: left .7s;
    ;
  }

  .nav-container-togglebtn {
    visibility: visible;
    transition: left .7s;
    left: 0;
  }

  .nav-container-togglebtn-active {
    left: 40vw;
    height: 100vh;
    width: 70vw;
    transition: left .7s;
}

  .nav-container-togglebtn svg {
    height: 15vw;
    width: 15vw;
    transition:
      -webkit-transform .7s,
      -moz-transform .7s,
      -o-transform .7s,
      transform .7s;
  }

  .nav-container-togglebtn-active svg {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);

  }

  .nav-container > div {
    width: 40vw;
  }

}


/* EmptyNav */

@media (max-width: 749px) {
  .emptynav-container {
    visibility: hidden;
    position: absolute;
  }
}

/* ProjectDetail */

.projectdetail-container {
  background-color: var(--projects-color);
  transition: background-color .7s;
  display: flex;
  flex-direction: column;
  height: auto;
}

.projectdetail-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projects-title {
  font-weight: 600;
  margin: 42px auto 54px;
}

.projectdetail-details {
  display: flex;
  /* height: 100vh; */
  flex-direction: row;
  padding: 8px;
  margin: 0 36px 64px;
  cursor: pointer;
  transition: background-color .3s;
  align-items: center;
  justify-content: space-between;
}

.projectdetail-details:hover {
  background-color: #7e9ada;
}

.projectdetail-details h2 {
  border-bottom: 1px solid white;
}

.projectdetail-details h3 {
  font-weight: 400;
}

.projectdetail-details ul {
  font-weight: 300;
}

.projectdetail-snapshot-img {
  width: 30vw;
  height: min-content;
  margin: 0 2vw 0 0;
  border: 5px solid #18284e;
}

.projectdetail-snapshot-img-flipped {
  margin: 0 0 0 2vw;
}

@media (max-width:749px) {

  .projectdetail-snapshot-img {
    width: 80vw;
    margin: 0;
  }

  .projectdetail-container {
    height: auto;
    padding: 0 0 80px 0;
  }

  .projectdetail-details-container {
    flex-direction: column;
  }

  .projectdetail-details {
    width: 80vw;
    border-radius: 5px;
    flex-direction: column;
  }
}


/* Badges */

.badges-container {
  background-color: var(--skills-color);
  transition: background-color .7s;
  display: flex;
  overflow-x: hidden;
}

.badges-circle-container {
  --d: 4.5em; /* image size */
  --rel: 1; /* how much extra space we want between images, 1 = one image size */
  --r: calc(.5*(1 + var(--rel))*var(--d)/var(--tan)); /* circle radius */
  --s: calc(2*var(--r) + var(--d)); /* container size */
  position: relative;
  width: var(--s); height: var(--s);
  margin: 0 auto;
  align-self: center;
}

.badges-circle-container div {
  position: absolute;
  top: 43%; left: 43%;
  transform: translate(-50%, -50%);
  padding: calc(-.5*var(--d));
  width: var(--d); height: var(--d);
  --az: calc(var(--i)*1turn/var(--m));
  transform:
  rotate(var(--az))
  translate(var(--r))
    rotate(calc(-1*var(--az)))
  }


.badges-circle-container h1 {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badges-icon-container svg {
  cursor: pointer;
  height: 4em;
  width: 4em;
  transition:
    width .7s,
    height .7s;
}

.badges-icon-container svg:hover {
  height: 120%;
  width: 120%;
}

@media (max-width: 749px) {
  .badges-circle-container {
    top: 50%;
    height: auto;
    --rel: .3;
  }
}

/* Contact */

.contact-container {
  background-color: var(--contact-color);
  transition: background-color .7s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-links {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 75%;
}

.contact-p {
  width: 75%;
  text-align: center;
}

@media (max-width: 749px) {
  /* .contact-links {
    flex-direction: column;
  } */
}
